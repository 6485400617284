import "core-js/modules/es6.object.assign";
import "core-js/modules/es6.string.link";
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Grid, Post, SEO } from 'components';
import { useNavIntro } from 'hooks';
import { className } from 'utils';
import styles from './styles/content.module.scss';

var ContentPage = function ContentPage(_ref) {
  var _ref$data$page = _ref.data.page,
      navIntro = _ref$data$page.navIntro,
      posts = _ref$data$page.posts,
      seo = _ref$data$page.seo,
      pathname = _ref.location.pathname;
  useNavIntro(navIntro);
  var gridPosts = useMemo(function () {
    return posts.reduce(function (_ref2, post, i) {
      var col1 = _ref2[0],
          col2 = _ref2[1];
      (i % 2 === 0 ? col1 : col2).push(post);
      return [col1, col2];
    }, [[], []]);
  }, [posts]);
  /**
   * Renders the given array of posts.
   *
   * @param {object[]} posts an array of posts
   * @returns the list markup of the given posts
   */

  var renderPosts = function renderPosts(posts) {
    return React.createElement("ul", {
      className: styles.postList
    }, posts.map(function (_ref3, i) {
      var author = _ref3.author,
          link = _ref3.link,
          linkText = _ref3.linkText,
          previewImage = _ref3.previewImage,
          title = _ref3.title;
      return React.createElement("li", {
        key: i,
        className: styles.postItem
      }, React.createElement(Post, {
        author: author,
        link: link,
        linkText: linkText,
        previewImage: previewImage,
        title: title
      }));
    }));
  };

  return React.createElement("div", {
    className: styles.container
  }, React.createElement(SEO, Object.assign({}, seo, {
    pathname: pathname
  })), React.createElement(Grid, Object.assign({
    tag: "section",
    hasMaxWidth: true
  }, className(styles.postSection, styles.postSectionDesktop)), React.createElement("ul", {
    className: styles.columnList
  }, gridPosts.map(function (postList, i) {
    return React.createElement("li", {
      key: i,
      className: styles.columnItem
    }, renderPosts(postList));
  }))), React.createElement(Grid, Object.assign({
    tag: "section",
    hasMaxWidth: true
  }, className(styles.postSection, styles.postSectionMobile)), renderPosts(posts)));
};

ContentPage.propTypes = {
  data: PropTypes.shape({
    page: PropTypes.shape({
      navIntro: PropTypes.object,
      posts: PropTypes.arrayOf(PropTypes.object).isRequired,
      seo: PropTypes.object.isRequired
    }).isRequired
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  }).isRequired
};
export default ContentPage;
export var query = "709314420";